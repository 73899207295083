import React, { useCallback, useEffect, useState } from 'react';
import { axiosGetRequest, axiosPatchRequest, axiosPostRequest } from '../../../utils/axiosRequests';
import { ADMIN_MANY_ORDERS_API_PATHS, ADMIN_ORDER_API_PATHS } from '../constants/path.constants';
import Cookies from 'js-cookie';
import { useSnackbar } from 'notistack';
import { deliveryTypeToLabel } from '../../delivery/utils/delivery.utils';
import moment from 'moment/moment';
import {
  Event as EventIcon,
  LocalShipping as LocalShippingIcon,
  Schedule as ScheduleIcon,
  Payment as PaymentIcon,
} from '@material-ui/icons';

export const useArchiveOrdersByDate = ({ deliveryDate, segment }) => {
  const token = Cookies.get('jwt');

  return useCallback(
    async ({ includeLabels = false }) => {
      return axiosPatchRequest(ADMIN_MANY_ORDERS_API_PATHS.ARCHIVE, token, {
        date: deliveryDate,
        includeLabels,
        segment,
      });
    },
    [token, deliveryDate, segment]
  );
};

export const useArchiveOrderById = ({ orderId }) => {
  const token = Cookies.get('jwt');

  return useCallback(async () => {
    return axiosPatchRequest(ADMIN_ORDER_API_PATHS.ARCHIVE_BY_ID, token, { orderId });
  }, [token, orderId]);
};

export const useUnarchiveOrdersByDate = ({ deliveryDate, segment }) => {
  const token = Cookies.get('jwt');

  return useCallback(async () => {
    return axiosPatchRequest(ADMIN_MANY_ORDERS_API_PATHS.UNARCHIVE, token, { date: deliveryDate, segment });
  }, [token, deliveryDate, segment]);
};

export const useGetOrdersToArchive = ({ deliveryDate, segment, trigger }) => {
  const token = Cookies.get('jwt');
  const { enqueueSnackbar } = useSnackbar();

  const [result, setResult] = useState({
    loading: true,
    orders: [],
    stats: undefined,
  });

  useEffect(() => {
    let url = `${ADMIN_MANY_ORDERS_API_PATHS.TO_ARCHIVE}/${deliveryDate}?includeStats=true`;
    if (segment) url += `&segment=${segment}`;

    const fetchData = () => axiosGetRequest(`${url}`, token, true);

    fetchData()
      .then((response) => {
        const { orders, stats } = response.data.data;

        setResult({
          loading: false,
          orders,
          stats,
        });
      })
      .catch((error) => {
        const message =
          error.response?.data?.message ?? 'Une erreur est survenue lors de la récupération des commandes à archiver';

        enqueueSnackbar(message, {
          variant: 'error',
        });
      });
  }, [deliveryDate, segment, trigger, token, enqueueSnackbar]);

  return result;
};

export const useGetOrdersLabels = ({ deliveryDate }) => {
  const token = Cookies.get('jwt');

  const { enqueueSnackbar } = useSnackbar();

  return useCallback(async () => {
    return axiosGetRequest(ADMIN_MANY_ORDERS_API_PATHS.GET_LABELS_BY_DATE.replace(':deliveryDate', deliveryDate), token)
      .then((response) => response.data.data.labels)
      .catch((error) => {
        const message =
          error.response?.data?.message ?? 'Une erreur est survenue lors de la récupération des étiquettes';

        enqueueSnackbar(message, {
          variant: 'error',
        });

        return undefined;
      });
  }, [token, deliveryDate]);
};

export const useGetLabelsByOrderId = ({ orderId }) => {
  const token = Cookies.get('jwt');

  const { enqueueSnackbar } = useSnackbar();

  return useCallback(async () => {
    return axiosGetRequest(ADMIN_ORDER_API_PATHS.GET_LABELS_BY_ID.replace(':orderId', orderId), token)
      .then((response) => response.data.labels)
      .catch((error) => {
        const message =
          error.response?.data?.message ?? 'Une erreur est survenue lors de la récupération des étiquettes';

        enqueueSnackbar(message, {
          variant: 'error',
        });

        return undefined;
      });
  }, [token, orderId]);
};

export const useGetOrderHeaders = (order) => {
  if (!order) return {};

  const { deliveryInfos } = order;

  const isPaid = order.status === 'paid' || order.status === 'partial';

  const paymentMethod = isPaid ? (order.status === 'paid' ? 'Carte bancaire' : 'Edenred') : 'Non payée';

  return {
    paymentHeader: {
      key: 'payment',
      label: 'Méthode de paiement',
      value: paymentMethod,
      Icon: <PaymentIcon />,
    },
    deliveryTypeHeader: {
      key: 'deliveryType',
      label: 'Type de livraison',
      value: deliveryTypeToLabel(deliveryInfos.deliveryType),
      Icon: <LocalShippingIcon />,
    },
    deliveryDateHeader: {
      key: 'deliveryDate',
      label: 'Date de livraison',
      value: moment(deliveryInfos.deliveryDate).format('dddd DD MMMM YYYY'),
      Icon: <EventIcon />,
    },
    deliveryScheduleHeader: {
      key: 'deliverySchedule',
      label: 'Horaire de livraison',
      value: deliveryInfos.deliverySchedule,
      Icon: <ScheduleIcon />,
    },
  };
};

export const useGetDeliveriesByDate = ({ deliveryDate, segment }) => {
  const { enqueueSnackbar } = useSnackbar();
  const token = Cookies.get('jwt');

  const INITIAL_STATE = {
    loading: true,
    orders: {
      home: [],
      relay: [],
      eco: [],
    },
  };

  const [result, setResult] = useState(INITIAL_STATE);

  useEffect(() => {
    let url = `${ADMIN_MANY_ORDERS_API_PATHS.GET_DELIVERY_NOTES.replace(':deliveryDate', deliveryDate)}`;
    if (segment) url += `?segment=${segment}`;

    const fetchData = () => axiosGetRequest(url, token);

    fetchData()
      .then((response) => {
        setResult({
          loading: false,
          orders: {
            ...response.data.orders,
          },
        });
      })
      .catch((error) => {
        const message =
          error.response?.data?.message ?? 'Une erreur est survenue lors de la récupération des commandes à archiver';

        enqueueSnackbar(message, {
          variant: 'error',
        });
      });
  }, [deliveryDate, segment, token]);

  return result;
};

export const useGetLastUserOrders = ({ userId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const token = Cookies.get('jwt');

  const INITIAL_STATE = {
    loading: true,
    orders: [],
  };

  const [result, setResult] = useState(INITIAL_STATE);

  useEffect(() => {
    let url = `${ADMIN_MANY_ORDERS_API_PATHS.GET_LAST_USER_ORDERS.replace(':userId', userId)}`;

    const fetchData = () => axiosGetRequest(url, token);

    fetchData()
      .then((response) => {
        setResult({
          loading: false,
          orders: response.data.data.orders,
        });
      })
      .catch((error) => {
        const message =
          error.response?.data?.message ??
          `Une erreur est survenue lors de la récupération des dernières commandes de l'utilisateur`;

        enqueueSnackbar(message, {
          variant: 'error',
        });
      });
  }, [userId, token]);

  return result;
};

export const useAdminGetOrderById = ({ orderId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const token = Cookies.get('jwt');

  const INITIAL_STATE = {
    loading: true,
    order: null,
  };

  const [result, setResult] = useState(INITIAL_STATE);

  useEffect(() => {
    setResult(INITIAL_STATE);

    const fetchData = () => axiosGetRequest(ADMIN_ORDER_API_PATHS.GET_ORDER_BY_ID(orderId), token);

    fetchData()
      .then((response) => {
        setResult({
          loading: false,
          order: response.data.data.order,
        });
      })
      .catch((error) => {
        enqueueSnackbar(
          error.response?.data?.message ?? `Une erreur est survenue lors de la récupération de cette commande`,
          { variant: 'error' }
        );
      });
  }, [orderId, token]);

  return result;
};
