import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useParams } from 'react-router';

import { useReactToPrint } from 'react-to-print';
import Cookies from 'js-cookie';

// # CUSTOM COMPONENTS
import { makeStyles } from '@material-ui/core/styles';

// # UTILS

// # MATERIAL UI
import { Grid, Button } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { axiosGetRequest, axiosPatchRequest } from '../../../utils/axiosRequests';
import OrderDetails from '../../../components/Order/OrderDetails';
import { useArchiveOrderById, useGetLabelsByOrderId } from '../../../shared/order/hooks/order.hooks';
import PrintLabelsDialog from '../../../components/Order/Dialog/PrintLabelsDialog';

// # CONSTANTS
import { ADMIN_ORDER_APP_PATHS } from '../../../shared/order/constants/path.constants';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
  },
  flex: {
    display: 'flex',
    width: '99%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  margin: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '596px',
  },
  button: {
    margin: '0 5px 0 5px',
    height: 'fit-content',
  },
}));

export default function OrderPage() {
  const classes = useStyles();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const token = Cookies.get('jwt');
  const { orderId } = useParams();

  const [state, setState] = useState({
    order: {},
    labels: {},
    loading: true,
  });
  const [isPrintDialogOpen, setIsPrintDialogOpen] = useState(false);

  const archiveOrder = useArchiveOrderById({ orderId });
  const getOrderLabels = useGetLabelsByOrderId({ orderId });

  useEffect(() => {
    axiosGetRequest(`/orders/${orderId}`, token).then((res) => {
      setState((prev) => ({
        ...prev,
        order: res.data.data.order,
        loading: false,
      }));
    });
  }, [orderId, token]);

  const handleChangeAdv = (value) => {
    axiosPatchRequest(`/orders/adv/${orderId}`, token, { advStatus: value })
      .then((res) => {
        setState((prev) => ({
          ...prev,
          order: res.data.data.order,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleArchiveAndPrint = () => {
    archiveOrder().then((res) => {
      setState((prev) => ({
        ...prev,
        order: {
          ...prev.order,
          advStatus: 'archived',
        },
        labels: res.data.labels,
      }));
      setIsPrintDialogOpen(true);
      handlePrint();
    });
  };

  const handleGetLabels = async () => {
    const labels = await getOrderLabels();

    const hasLabels = Boolean(labels?.products?.length || labels?.baskets?.length);
    setState((prev) => ({
      ...prev,
      labels: hasLabels ? labels : undefined,
    }));

    if (hasLabels) {
      setIsPrintDialogOpen(true);
      handlePrint();
    }
  };

  return (
    <Grid container spacing={3}>
      <div className={classes.flex}>
        <h2>Détails de la commande ID : {orderId}</h2>
        <div className={classes.margin}>
          <div>
            <Button
              variant="contained"
              color="primary"
              href={ADMIN_ORDER_APP_PATHS.UPDATE_ORDER.replace(':orderId', orderId)}
            >
              Modifier la commande
            </Button>
          </div>
        </div>
      </div>
      {state.loading ? (
        <Skeleton variant="rect" width={500} height={150} />
      ) : (
        <>
          <PrintLabelsDialog
            showCategoryHeader={false}
            ref={componentRef}
            isOpen={isPrintDialogOpen}
            labels={state.labels}
          />
          <OrderDetails
            order={state.order}
            methods={{
              handleChangeAdv,
              handleArchiveAndPrint,
              handleGetLabels,
            }}
            token={token}
          />
        </>
      )}
    </Grid>
  );
}
